import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import FAQ from '../components/common/FAQ'
import PricingPlans from '../components/pricing/PricingPlans'

const PricingPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Atlas offers straightforward pricing for individuals and teams. Get started with a free account today. No credit card required.',
        title: 'Atlas Pricing',
        type: 'website'
      }}
      title={'Atlas Pricing'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PricingPlans />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <FAQ />
        </Fade>
      </main>
    </Layout>
  )
}

export default PricingPage
